import * as React from "react";
import Layout from "../components/layout"
import pace500Img from "../images/pace500.3_ipad_resized_copy.png";
import pace500Video from "../images/Pace_500_Video.mp4"
import { pageStyles } from "../styles/helpers";
import "../styles/global-styles.css";

// styles
const imageStyles = {
  marginBottom: 0,
  width: "100%",
};
const videoStyles = {
  marginTop: "-4px",
  marginBottom: 0,
  width: "100%",
};

// markup
const pace500 = () => {
  return (
    <main style={pageStyles}>
      <title>Pace 500 Specs</title>
      <img style={imageStyles} src={pace500Img} />
      <video style={videoStyles} crossOrigin="anonymous" controls={false} autoPlay loop muted>
        <source src={pace500Video} type="video/mp4" />
      </video>
    </main>
  );
};

export default pace500;
